.security-detail{
width: 100%;
padding: 5px;
}
.security-detail .title-image-con{
 /* height: 300px; */
    display: flex;
    justify-content: center;
}
.security-detail .title-image-con{
    max-height: 600px;
}
.security-detail .title-image-con img{
    object-fit: cover;
    width: 800px !important;

}
.security-detail .security-detail-content{
    font-size: 1.7rem;
    color: #2A2F83;
}
.security-detail .security-detail-sub-content{
    color: #832b67;
    font-size: 1.2rem;
}
.security-detail  .security-detail-phone-number{
    color: #832b67;
    font-size: 1rem;
    font-weight: 600;
}
.security-images .image-container{
    width: 100%;
}
.security-images{
    width: 100%;
    display: flex;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    gap: 20px;
    /* row-gap: 20px; */
    /* background-color: blue; */
    justify-content: center;
    flex-wrap: wrap;
}
.security-images-con{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.security-images >.image-item{
    /* min-width: 400px; */
    /* max-width: 400px; */
    /* width: 100%;
    height: 1vh;
    background-color: red; */
    /* width: 400px; */
    /* background-color: red; */
    /* width: 48%; */

    max-width: 450px;
    height: 300px;
    /* overflow: hidden; */
    /* margin: '0% auto'; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.security-images .image-item img{
    /* border-radius: 20px; */
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* aspect-ratio: 1/4; */
    /* border-radius: 20px; */
    
        /* max-width: 400px; */
    /* heig ht: 10px; */
}

/* @media screen and (min-width: 1600px){
    
    .security-images .image-item{
        max-width: 500px;
        height: 400px;
    }
    
} */
/* @media screen and (min-width: 1600px){
    .security-images >.image-item{
        aspect-ratio: 3/4;
    }
} */
@media screen and (max-width: 769px){
    .security-detail .security-detail-content{
        font-size: 1.3rem;
    }
    .security-detail .image-item{
        /* width: 90%; */
        /* height: 200px; */
    }
    .security-detail .title-image-con img{
        /* min-height: 250px !important; */
        width: 500px !important;
    }
}