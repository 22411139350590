.app_links{
    display: flex;
    justify-content: end;
}
.app_links ul{
    width: 100%;
    margin-top: -10px;
    list-style: none;
    display: flex;
    gap: 3px;
    justify-content: end;
}
.app_links .contact-text{
    color: #2a2f83;
    font-size: 18px;
    text-align: right;
    /* font-weight: 600; */
}
.app_links .mobile-icon{
    
}
@media (max-width: 767px) {
    .app_links{
        display: flex;
        /* justify-content: center; */
    }
    .app_links ul {
        display: flex;
        gap: 6px;
        /* flex-direction: column; */
        justify-content: end;
        flex-wrap: wrap;
    }
    .app_links .contact-text{
        font-size: 16px;
    }
    .app_links .contact-text span{
        display: none;
    }
    .app_links ul{
        /* margin-top: -5px; */
    }
}